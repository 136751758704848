var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { reportService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from "~/core/decorator";
import { FilterService } from '~/utils/filter.service';
var ClientBackMoneyService = /** @class */ (function () {
    function ClientBackMoneyService() {
    }
    /**
     * 查询委托方回款报表
     */
    ClientBackMoneyService.prototype.getPrincipalPayment = function (data, loading) {
        var model = Object.assign({}, data);
        var dateSelect = FilterService.dateRanageFormat(model.dateSelect, "yyyy-MM-dd");
        return this.netService.send({
            server: reportService.reportController.getPrincipalPayment,
            data: {
                principalId: model.principalId,
                batchNumber: model.batchNumber,
                startDate: dateSelect.start,
                endDate: dateSelect.end
            },
            loading: loading
        });
    };
    /**
     * 导出委托方回款报表
     */
    ClientBackMoneyService.prototype.exportPrincipalPayment = function (data, loading) {
        var model = Object.assign({}, data);
        var dateSelect = FilterService.dateRanageFormat(model.dateSelect, "yyyy-MM-dd");
        return this.netService.send({
            server: reportService.reportController.exportPrincipalPayment,
            data: {
                principalId: model.principalId,
                batchNumber: model.batchNumber,
                startDate: dateSelect.start,
                endDate: dateSelect.end
            },
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], ClientBackMoneyService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], ClientBackMoneyService.prototype, "getPrincipalPayment", null);
    __decorate([
        Debounce()
    ], ClientBackMoneyService.prototype, "exportPrincipalPayment", null);
    return ClientBackMoneyService;
}());
export { ClientBackMoneyService };

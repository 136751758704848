var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { State } from "vuex-class";
import { ClientBackMoneyService } from "~/services/report-service/client-back-money.service";
var ClientBackMoney = /** @class */ (function (_super) {
    __extends(ClientBackMoney, _super);
    function ClientBackMoney() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            principalId: "",
            batchNumber: "",
            startDate: "",
            dateSelect: []
        };
        _this.dataSet = [];
        return _this;
    }
    ClientBackMoney.prototype.mounted = function () { };
    /**
     * 刷新列表
     */
    ClientBackMoney.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.clientBackMoneyService
            .getPrincipalPayment(this.searchModel, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    /**
     * 导出数据
     */
    ClientBackMoney.prototype.exportDataClick = function () {
        var _this = this;
        this.clientBackMoneyService
            .exportPrincipalPayment(this.searchModel, this.loading)
            .subscribe(function (data) {
            _this.$message.success("正在导出,请关注任务中心!");
            _this.refreshData();
        });
    };
    __decorate([
        Dependencies(ClientBackMoneyService)
    ], ClientBackMoney.prototype, "clientBackMoneyService", void 0);
    __decorate([
        State
    ], ClientBackMoney.prototype, "principalList", void 0);
    __decorate([
        State
    ], ClientBackMoney.prototype, "clientList", void 0);
    ClientBackMoney = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], ClientBackMoney);
    return ClientBackMoney;
}(Vue));
export default ClientBackMoney;
